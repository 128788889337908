<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Редактировать администратора</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editAdminForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Никнейм</label>
                <md-input v-model.trim="nickname" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>ФИО</label>
                <md-input v-model.trim="name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>E-mail</label>
                <md-input v-model.trim="email" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Телефон</label>
                <md-input
                  v-model="phone"
                  v-mask="'+# (###) ###-##-##'"
                  md-counter="false"
                  maxlength="18"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Роль</label>
                <md-select v-model="role" @focus="reset">
                  <md-option
                    v-for="(item, index) in roles"
                    :key="index"
                    :value="item"
                    placeholder="Роль"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required|min:8|max:20"
              vid="confirmation"
            >
              <md-field
                :class="{ error: errors.length }"
                :md-toggle-password="true"
              >
                <ValidationError :errors="errors" />
                <label>Пароль</label>
                <md-input
                  v-model.trim="password"
                  type="password"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required|confirmed:confirmation"
            >
              <md-field
                :class="{ error: errors.length }"
                :md-toggle-password="true"
              >
                <ValidationError :errors="errors" />
                <label>Повторите пароль</label>
                <md-input
                  v-model.trim="password_confirmation"
                  type="password"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <md-checkbox v-model="active">Активность</md-checkbox>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="rewriteAdmin">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      nickname: "",
      name: "",
      password: "",
      password_confirmation: "",
      phone: "",
      email: "",
      role: null,
      active: false,
    };
  },

  computed: {
    ...mapState({
      admin: (state) => state.admins.admin,
      roles: (state) => state.admins.roles,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);

    let success = await this.getAdmin({ admin_id: +this.$route.params.id });
    if (success) {
      this.nickname = this.admin.nickname;
      this.name = this.admin.name;
      this.phone = this.admin.phone;
      this.email = this.admin.email;
      this.role = this.admin.role;
      this.active = this.admin.status == "active";
    }

    await this.getRoles();

    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("admins", ["getAdmin", "updateAdmin", "getRoles"]),

    async rewriteAdmin() {
      const isValid = await this.$refs.editAdminForm.validate();
      if (!isValid) {
        return;
      }

      let admin = {
        nickname: this.nickname,
        name: this.name,
        phone: this.phone.replaceAll(/[-() ]/g, ""),
        email: this.email,
        role: this.role,
        active: +this.active,
        password: this.password,
      };

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateAdmin({ id: this.admin.id, admin });
      if (success) {
        this.$router.push(`/admins/${this.admin.id}`);
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
